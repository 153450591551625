import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import { Container } from './styles';
import React, { Component } from 'react';
import client from '../Http';
import {
  TrygApplicationId,
  EidsivaApplicationId,
  NearsensApplicationId,
  CopiaxApplicationId,
  IotilitiApplicationId,
  TekamApplicationId,
  Safe4CareApplicationId,
  LarmplusApplicationId,
  FolklarmApplicationId,
  KeyfreeApplicationId,
  WaooApplicationId,
  ForebyggApplicationId,
  HomelyApplicationId,
  NimlyApplicationId,
  SalusApplicationId,
} from '../utils/applications';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { isValidToken: false, loading: true, companyId: null };
  }

  async componentDidMount() {
    try {
      const { data } = await client.post('/users/forgotten-password/check-token', {
        token: this.props.token,
      });

      const { data: translations } = await client.get(
        `/notification/translations/websites?language=EN${
          data.companyId ? '&companyId=' + data.companyId : ''
        }`,
      );
      this.changeFavicon(data.companyId);
      this.setState({
        isValidToken: data.valid,
        loading: false,
        translations,
        companyId: data.companyId,
      });
    } catch (err) {
      this.setState({
        isValidToken: false,
        loading: false,
      });
    }
  }

  changeFavicon(companyId) {
    if (!companyId) {
      return;
    }
    const favicon = document.getElementById('favicon');
    if (favicon) {
      switch (companyId) {
        case TrygApplicationId:
          favicon.href = 'favicon_tryg.ico';
          break;
        case EidsivaApplicationId:
          favicon.href = 'favicon_eidsiva.ico';
          break;
        case NearsensApplicationId:
          favicon.href = 'favicon_nearsens.ico';
          break;
        case CopiaxApplicationId:
          favicon.href = 'favicon_copiax.ico';
          break;
        case IotilitiApplicationId:
          favicon.href = 'favicon_iotiliti.ico';
          break;
        case TekamApplicationId:
          favicon.href = 'favicon_tekam.ico';
          break;
        case Safe4CareApplicationId:
          favicon.href = 'favicon_safely.ico';
          break;
        case LarmplusApplicationId:
          favicon.href = 'favicon_larmplus.ico';
          break;
        case FolklarmApplicationId:
          favicon.href = 'favicon_larm.ico';
          break;
        case KeyfreeApplicationId:
          favicon.href = 'favicon_keyfree.ico';
          break;
        case WaooApplicationId:
          favicon.href = 'favicon_waoo.ico';
          break;
        case ForebyggApplicationId:
          favicon.href = 'favicon_forebygg.ico';
          break;
        case HomelyApplicationId:
          favicon.href = 'favicon_homely.ico';
          break;
        case NimlyApplicationId:
          favicon.href = 'favicon_nimly.ico';
          break;
        case SalusApplicationId:
          favicon.href = 'favicon_salus.ico';
          break;
        default:
          favicon.href = 'favicon.ico';
          break;
      }
    }
  }

  getTranslation(messageCode) {
    if (this.state.translations) {
      return this.state.translations.find((item) => item.messageCode === messageCode) || '';
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <Container>
          <Header
            getTranslation={(messageCode) => this.getTranslation(messageCode)}
            companyId={this.state.companyId}
          />
          <Content isValidToken={this.state.isValidToken} companyId={this.state.companyId} />
          <Footer
            getTranslation={(messageCode) => this.getTranslation(messageCode)}
            companyId={this.state.companyId}
          />
        </Container>
      );
    } else {
      return '';
    }
  }
}

export default Index;
