import React, { Component } from 'react';
import { StyledHeader } from './styles';
import ParnerLogo from '../Content/Shared/PartnerLogo';
import {
  NimlyApplicationId,
  EidsivaApplicationId,
  ForebyggApplicationId,
  HomelyApplicationId,
  KeyfreeApplicationId,
  Safe4CareApplicationId,
  TrygApplicationId,
  WaooApplicationId,
  SalusApplicationId,
} from '../../utils/applications';
import HomelyHeader from './Customization/HomelyHeader';
import TrygHeader from './Customization/TrygHeader';
import EidsivaHeader from './Customization/EidsivaHeader';
import KeyfreeHeader from './Customization/KeyfreeHeader';
import WaooHeader from './Customization/WaooHeader';
import ForebyggHeader from './Customization/ForebyggHeader';
import NimlyHeader from './Customization/NimlyHeader';
import SalusHeader from './Customization/SalusHeader';
import Safe4CareHeader from './Customization/Safe4CareHeader';

class Header extends Component {
  render() {
    switch (this.props.companyId) {
      case HomelyApplicationId:
        return (
          <HomelyHeader getTranslation={(messageCode) => this.props.getTranslation(messageCode)} />
        );
      case TrygApplicationId:
        return (
          <TrygHeader getTranslation={(messageCode) => this.props.getTranslation(messageCode)} />
        );
      case EidsivaApplicationId:
        return (
          <EidsivaHeader getTranslation={(messageCode) => this.props.getTranslation(messageCode)} />
        );
      case Safe4CareApplicationId:
        return (
          <Safe4CareHeader getTranslation={(messageCode) => this.props.getTranslation(messageCode)} />
        );
      case KeyfreeApplicationId:
        return (
          <KeyfreeHeader getTranslation={(messageCode) => this.props.getTranslation(messageCode)} />
        );
      case WaooApplicationId:
        return (
          <WaooHeader getTranslation={(messageCode) => this.props.getTranslation(messageCode)} />
        );
      case ForebyggApplicationId:
        return (
          <ForebyggHeader
            getTranslation={(messageCode) => this.props.getTranslation(messageCode)}
          />
        );
      case NimlyApplicationId:
        return (
          <NimlyHeader getTranslation={(messageCode) => this.props.getTranslation(messageCode)} />
        );
      case SalusApplicationId:
        return (
          <SalusHeader getTranslation={(messageCode) => this.props.getTranslation(messageCode)} />
        );
      default:
        return (
          <StyledHeader>
            <ParnerLogo
              getTranslation={(messageCode) => this.props.getTranslation(messageCode)}
              isHeaderLogo={true}
            />
          </StyledHeader>
        );
    }
  }
}

export default Header;
