export const HomelyApplicationId = 'a21f32b6-4ac9-4c85-b97a-8b6ae565f37c';
export const TrygApplicationId = 'a418725f-d9c5-49cb-a761-d955e0f2a09d';
export const EidsivaApplicationId = '625b4442-ebff-41f0-af9b-05638918ac27';
export const NearsensApplicationId = '51e69f47-2137-41a0-af0a-c97e795ce3d5';
export const CopiaxApplicationId = '59211d13-066c-4903-ba43-a4d212d79b72';
export const IotilitiApplicationId = 'c1919f73-870f-45c8-a79c-0c19631d7132';
export const TekamApplicationId = '94d06105-442c-47f4-8545-ef5184136e00';
export const Safe4CareApplicationId = 'ab58cff2-a1c0-4198-860f-342d2188781a';
export const LarmplusApplicationId = 'ea1bdd1a-9636-4642-a36e-5c45978a840f';
export const FolklarmApplicationId = 'a6a2b374-2101-4427-9180-553a92e33d26';
export const KeyfreeApplicationId = 'b960e7e6-02bd-490b-8c90-e6c428c45eea';
export const WaooApplicationId = '01fb980b-b7fa-41c2-b6a2-4db7fa32a45a';
export const ForebyggApplicationId = '474d2082-d168-4fc4-b283-104ee082a80c';
export const NimlyApplicationId = '90ded287-2356-4007-ac39-d3e1261afb59';
export const SalusApplicationId = '93bd9ef6-8bd0-4ad0-b045-5644fd62ef70';
